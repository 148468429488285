/* eslint-disable no-restricted-imports */

/**
 * Module dependencies.
 */

import { CSSProperties, PropsWithChildren } from 'react';
import { isExternalUrl } from 'src/core/utils/url';
import { useInternalUrl } from 'src/hooks/use-internal-url';
import { useUpholdAppUrl } from 'src/hooks/use-uphold-app-url';
import NextLink, { LinkProps } from 'next/link';

/**
 * `Props` component.
 */

export type Props = PropsWithChildren<LinkProps> & {
  className?: string;
  style?: CSSProperties;
};

/**
 * Export `RouterLink` component.
 */

export const RouterLink = (props: Props) => {
  const { legacyBehavior, locale, passHref, replace, scroll, shallow, ...rest } = props;
  const isInternalUrl = useInternalUrl();
  const href = typeof props.href === 'string' ? props.href : props.href?.href;
  const getAppProps = useUpholdAppUrl(props?.href as string);

  if (href && (!isInternalUrl(href) || isExternalUrl(href))) {
    return <a {...rest} href={href} {...getAppProps} />;
  }

  const routerProps = { legacyBehavior, locale, passHref, replace, scroll, shallow };

  return <NextLink {...routerProps} {...rest} {...getAppProps} prefetch={false} />;
};
