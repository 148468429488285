/* eslint-disable new-cap */

/**
 * Module dependencies.
 */

import { colors } from './colors';
import { createCssVariables } from './utils/mixins';
import { css } from 'styled-components';
import { elevations } from './elevations';
import { media } from './media';

/**
 * Export `rootVariables`.
 */

export const rootVariables = css`
  :root {
    /* Colors */
    ${createCssVariables(colors, 'color')}

    --color-background: var(--color-white);
    --color-primary: var(--color-primaryForLight);
    --color-text: var(--color-neutral90);

    [data-theme='dark'] {
      --color-background: var(--color-neutral105);
      --color-primary: var(--color-primaryForDark);
      --color-text: var(--color-neutral0);
    }

    /* Box shadows */
    ${createCssVariables(elevations, 'box-shadow')}

    /* Font family */
    --font-family-default: 'proxima-nova', sans-serif;

    /* Gradients */
    --hero-gradient-base-color: var(--color-white);
    --hero-white-overlay-gradient: linear-gradient(
      180deg,
      color-mix(in srgb, var(--color-white), transparent 100%) 70.26%,
      color-mix(in srgb, var(--color-white), transparent 30%) 86%,
      var(--color-white) 100%
    );
    --hero-black-overlay-gradient: linear-gradient(
      180deg,
      color-mix(in srgb, var(--color-black), transparent 100%) 70.26%,
      color-mix(in srgb, var(--color-black), transparent 30%) 86%,
      var(--color-black) 100%
    );

    /* Layout */
    --border-radius: 16px;
    --border-radius-sm: 8px;
    --container-max-width: 1280px;
    --container-fluid-max-width: 100%;
    --container-narrow-max-width: 992px;
    --grid-row-gap: 8px;
    --gutter: 24px;
    --gutter-navbar-x: 16px;
    --gutter-navbar-y: 10px;
    --gutter-cards: -8px;

    ${media.min.ms`
      --gutter-navbar-y: 16px;
    `}

    /* Navbar */
    --navbar-height: 60px;
    --navbar-offset-top: var(--gutter);

    /* stylelint-disable */
    --nav-bottom-banner-height: 0px;
    --top-banner-height: 0px;
    /* stylelint-enable */

    ${media.min.md`
      --navbar-height: 86px;
    `}

    ${media.min.xl`
      --navbar-offset-top: 16px;
    `}

    /* Transitions */
    --transition-animation: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-default: 350ms var(--transition-animation);
    --transition-fast: 150ms var(--transition-animation);
    --transition-slow: 500ms ease-in-out;

    /* Z-indexes */
    --z-index-modal-underlay: 98;
    --z-index-navbar: 97;
    --z-index-toast: 99;
    --z-index-banner: 96;
    --z-index-page-transition: 96;
    --z-index-preview-layer: 999;
  }
`;
