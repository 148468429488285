/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { HelpText, Label } from 'src/components/core/forms/styles';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'input'>, 'onChange' | 'value'> & {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  helpText?: string;
  id: string;
  isTextArea?: boolean;
  label?: string;
  labelHasHtml?: boolean;
  maxLength?: number;
  sublabel?: string;
  value?: unknown;
};

/**
 * Export `FormGroupProps` type.
 */

export type FormGroupProps = Omit<Props, 'children' | 'size'> & {
  size?: 'default' | 'medium' | 'small';
  variant?: 'filled' | 'ghost';
};

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  --labels-gap: 16px;
  --input-background-color: var(--color-neutral05);
  --input-border-color: transparent;
  --input-color: var(--color-neutral50);
  --input-icon-color: var(--color-neutral50);
  --input-helptext-color: var(--color-neutral50);
  --input-label-color: var(--color-neutral90);
  --input-placeholder-color: var(--color-neutral50);
  --input-shadow: var(--box-shadow-lightS);

  &[data-value='true'] {
    --input-color: var(--color-neutral90);
  }

  :hover {
    --input-background-color: var(--color-neutral10);
  }

  :focus-within {
    --input-background-color: var(--color-neutral10);
    --input-placeholder-color: var(--color-neutral70);
    --input-color: var(--color-neutral90);
    --input-icon-color: var(--color-neutral90);
  }

  &[data-error='true'] {
    --input-border-color: var(--color-critical60);
    --input-helptext-color: var(--color-critical70);
    --input-label-color: var(--color-critical70);
  }

  &[data-disabled='true'] {
    --input-background-color: var(--color-neutral05);
    --input-color: var(--color-neutral30);
    --input-icon-color: var(--color-neutral30);
    --input-placeholder-color: var(--color-neutral30);
    --input-helptext-color: var(--color-neutral30);
    --input-label-color: var(--color-neutral30);

    cursor: not-allowed;
    pointer-events: none;
  }

  [data-theme='dark'] & {
    --input-background-color: var(--color-neutral90);
    --input-border-color: transparent;
    --input-color: var(--color-neutral40);
    --input-icon-color: var(--color-neutral40);
    --input-helptext-color: var(--color-neutral40);
    --input-label-color: var(--color-neutral0);
    --input-placeholder-color: var(--color-neutral40);
    --input-shadow: var(--box-shadow-darkS);

    &[data-value='true'] {
      --input-color: var(--color-neutral0);
    }

    :hover {
      --input-background-color: var(--color-neutral80);
    }

    :focus-within {
      --input-background-color: var(--color-neutral80);
      --input-placeholder-color: var(--color-neutral0);
      --input-color: var(--color-neutral0);
      --input-icon-color: var(--color-neutral0);
    }

    &[data-error='true'] {
      --input-border-color: var(--color-critical50);
      --input-helptext-color: var(--color-critical50);
      --input-label-color: var(--color-critical50);
    }

    &[data-disabled='true'] {
      --input-background-color: var(--color-neutral80);
      --input-color: var(--color-neutral60);
      --input-icon-color: var(--color-neutral60);
      --input-placeholder-color: var(--color-neutral60);
      --input-helptext-color: var(--color-neutral60);
      --input-label-color: var(--color-neutral60);
    }
  }

  display: grid;
  grid-template-areas: 'input';
  grid-template-columns: 1fr;
  width: 100%;

  &:not([data-empty='true']) {
    grid-template-areas: '. label .' '. sublabel .' 'input input input' '. helptext .';
    grid-template-columns: var(--labels-gap) 1fr var(--labels-gap);

    &[data-textarea='true'] {
      grid-template-areas: '. label . .' '. sublabel . .' 'input input input input' '. helptext charlimit .';
      grid-template-columns: var(--labels-gap) 1fr min-content var(--labels-gap);
    }
  }

  > * {
    margin-bottom: 8px;
  }
`;

/**
 * `CharLimit` styled component.
 */

const CharLimit = styled(Text).attrs({ variant: 'small' })`
  color: var(--input-lengthtext-color);
  grid-area: charlimit;
  text-align: right;
`;

/**
 * Export `FormGroup` component.
 */

export const FormGroup = (props: Props) => {
  const {
    children,
    className,
    disabled,
    error,
    helpText,
    id,
    isTextArea,
    label,
    labelHasHtml,
    maxLength,
    sublabel,
    value,
    ...rest
  } = props;

  return (
    <Wrapper
      {...rest}
      className={className}
      data-disabled={disabled}
      data-empty={!label && !helpText && !error}
      data-error={!!error}
      data-textarea={isTextArea}
      data-value={!!value}
    >
      {label &&
        (labelHasHtml ? (
          // @ts-expect-error - Pass `for` to label element.
          <RawHtml element={Label} htmlFor={id} style={{ gridArea: 'label' }}>
            {label}
          </RawHtml>
        ) : (
          <Label htmlFor={id} style={{ gridArea: 'label' }}>
            {label}
          </Label>
        ))}

      {sublabel && <Label style={{ fontWeight: 400, gridArea: 'sublabel' }}>{sublabel}</Label>}

      <div style={{ gridArea: 'input' }}>{children}</div>

      {(error || helpText) && <HelpText style={{ gridArea: 'helptext' }}>{error || helpText}</HelpText>}
      {isTextArea && maxLength && (
        <CharLimit>
          {typeof value === 'string' ? value.length : 0}

          {`/${maxLength}`}
        </CharLimit>
      )}
    </Wrapper>
  );
};

/**
 * `FormGroup` display name.
 */

FormGroup.displayName = 'FormGroup';
