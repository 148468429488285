/**
 * Module dependencies.
 */

import { Svg } from 'src/components/core/svg';
import { formControlSizes, formControlStyles, formControlVariants } from 'src/components/core/forms/styles';
import { textStyles } from 'src/styles/typography';
import styled, { css } from 'styled-components';

/**
 * Export `SelectWrapper` styled component.
 */

export const SelectWrapper = styled.div`
  --select-dropdown-menu-option-background-color: var(--color-white);
  --select-dropdown-menu-option-color: var(--color-neutral70);

  [data-theme='dark'] & {
    --select-dropdown-menu-option-background-color: var(--color-neutral95);
    --select-dropdown-menu-option-color: var(--color-neutral10);
  }

  position: relative;
  width: 100%;
`;

/**
 * `selectButtonReset` styles.
 */

const selectButtonReset = css`
  appearance: none;
  background: none;
  border: none;
  outline: none;
`;

/**
 * Export `SelectButton` styled component.
 */

export const SelectButton = styled.button`
  ${selectButtonReset}
  ${formControlStyles}

  ${Object.entries(formControlVariants).map(
    ([key, value]) => `
      [data-select-variant='${key}'] & {
        ${value}
      }
    `
  )}

  ${Object.entries(formControlSizes).map(
    ([key, value]) => `
      [data-select-size='${key}'] & {
        ${value}
      }
    `
  )}

  align-items: center;
  display: flex;
  gap: 2px;
  justify-content: space-between;
  padding-left: var(--input-padding-x);
  padding-right: calc(var(--input-padding-x) / 2);

  &[data-placeholder='true'] {
    color: var(--input-placeholder-color);
  }
`;

/**
 * Export `SelectOptions` styled component.
 */

export const SelectOptions = styled.ul`
  max-height: 280px;
  overflow-y: auto;
`;

/**
 * Export `SelectOption` styled component.
 */

export const SelectOption = styled.li`
  &[data-selected='true'] {
    --select-dropdown-menu-option-color: var(--color-neutral90);
    font-weight: 700;
  }

  &:hover,
  &:focus {
    --select-dropdown-menu-option-background-color: var(--color-neutral0);
  }

  [data-theme='dark'] & {
    &[data-selected='true'] {
      --select-dropdown-menu-option-color: var(--color-neutral0);
    }

    &:hover,
    &:focus {
      --select-dropdown-menu-option-background-color: var(--color-neutral90);
    }
  }

  ${textStyles.label2}

  align-items: center;
  background-color: var(--select-dropdown-menu-option-background-color);
  color: var(--select-dropdown-menu-option-color);
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  outline: none;
  padding: 12px 20px;
  transition: var(--transition-default);
  transition-property: background-color, color;
`;

/**
 * Export `DropdownIndicator` styled component.
 */

export const DropdownIndicator = styled(Svg)`
  color: var(--input-icon-color);
  flex: 0 0 24px;
  margin-left: auto;
  transition: color var(--transition-default);
  transition-property: color, transform;

  &[data-open='true'] {
    transform: rotate(180deg);
  }
`;

/**
 * Export `LoadingIndicator` styled component.
 */

export const LoadingIndicator = styled(Svg)`
  animation: spin 1s linear infinite;
  color: var(--color-primary);
  flex: 0 0 24px;
  margin-left: auto;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Export `NoResultsWrapper` styled component.
 */

export const NoResultsWrapper = styled.div`
  --select-no-results-color: var(--color-neutral70);
  --select-no-results-description-color: var(--color-neutral50);

  [data-theme='dark'] & {
    --select-no-results-color: var(--color-neutral10);
    --select-no-results-description-color: var(--color-neutral40);
  }

  display: grid;
  gap: 6px;
  padding: 24px 24px 32px;
  text-align: center;

  span:first-child {
    color: var(--select-no-results-color);
  }

  span:last-child {
    color: var(--select-no-results-description-color);
  }
`;
