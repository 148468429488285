/**
 * Constants.
 */

const linkedinPartnerId = process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID;

/**
 * Export `linkedinPixel`.
 */

export function linkedinPixel() {
  const scriptExists = document.getElementById('linkedinPixelScript');
  const noScriptExists = document.getElementById('linkedinPixelNoscript');

  if ((scriptExists && noScriptExists) || !linkedinPartnerId) {
    return;
  }

  const linkedinInitScript = document.createElement('script');
  const linkedinMainScript = document.createElement('script');
  const linkedinNoscript = document.createElement('noscript');

  linkedinInitScript.id = 'linkedinPixelScript';
  linkedinInitScript.type = 'text/javascript';
  linkedinInitScript.innerHTML = `
    _linkedin_partner_id = "${linkedinPartnerId}";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  `;

  linkedinMainScript.type = 'text/javascript';
  linkedinMainScript.async = true;
  linkedinMainScript.innerHTML = `
    (function(l) {
      if (!l) {
        window.lintrk = function(a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
      var s = document.getElementsByTagName('script')[0];
      var b = document.createElement('script');
      b.type = 'text/javascript';
      b.async = true;
      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
      s.parentNode.insertBefore(b,s);
    })(window.lintrk);
  `;

  linkedinNoscript.id = 'linkedinPixelNoscript';
  const img = document.createElement('img');
  img.height = 1;
  img.width = 1;
  img.style.display = 'none';
  img.alt = '';
  img.src = `https://px.ads.linkedin.com/collect/?pid=${linkedinPartnerId}&fmt=gif`;
  linkedinNoscript.appendChild(img);

  document.body.appendChild(linkedinInitScript);
  document.body.appendChild(linkedinMainScript);
  document.body.appendChild(linkedinNoscript);
}
