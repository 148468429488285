/**
 * Module dependencies.
 */

import React from 'react';

/**
 * Export `genericForwardRef` function.
 * Unlike `React.forwardRef`, this function allows for generic type inference.
 */

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export function genericForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
  displayName?: string
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  // @ts-expect-error: Force generic type inference
  const component = React.forwardRef(render) as any;

  if (displayName) {
    component.displayName = displayName;
  }

  return component;
}
