/**
 * Module dependencies.
 */

import { transparentize } from 'src/styles/utils/colors';

/**
 * Export color theme keys.
 */

export const variants = ['primary', 'secondary', 'neutral', 'ghost'] as const;

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = (typeof variants)[number];

/**
 * Export `buttonThemesConfig` constant.
 */

export const buttonThemesConfig: Record<
  'dark' | 'light',
  Record<ColorTheme, Record<'idle' | 'hover' | 'active' | 'disabled', Record<string, string>>>
> = {
  dark: {
    ghost: {
      active: {
        backgroundColor: transparentize('primaryForDark', 0.12),
        textColor: 'var(--color-primaryForDark)'
      },
      disabled: {
        backgroundColor: transparentize('neutral80', 0),
        textColor: 'var(--color-neutral60)'
      },
      hover: {
        backgroundColor: transparentize('primaryForDark', 0.08),
        textColor: 'var(--color-primaryForDark)'
      },
      idle: {
        backgroundColor: transparentize('primaryForDark', 0),
        textColor: 'var(--color-primaryForDark)'
      }
    },
    neutral: {
      active: {
        backgroundColor: 'var(--color-neutral70)',
        textColor: 'var(--color-neutral0)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral80)',
        textColor: 'var(--color-neutral60)'
      },
      hover: {
        backgroundColor: 'var(--color-neutral80)',
        textColor: 'var(--color-neutral0)'
      },
      idle: {
        backgroundColor: 'var(--color-neutral90)',
        textColor: 'var(--color-neutral0)'
      }
    },
    primary: {
      active: {
        backgroundColor: 'var(--color-primaryForDark40)',
        textColor: 'var(--color-neutral105)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral80)',
        textColor: 'var(--color-neutral60)'
      },
      hover: {
        backgroundColor: 'var(--color-primaryForDark30)',
        textColor: 'var(--color-neutral105)'
      },
      idle: {
        backgroundColor: 'var(--color-primaryForDark)',
        textColor: 'var(--color-neutral105)'
      }
    },
    secondary: {
      active: {
        backgroundColor: transparentize('primaryForDark', 0.24),
        textColor: 'var(--color-primaryForDark)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral80)',
        textColor: 'var(--color-neutral60)'
      },
      hover: {
        backgroundColor: transparentize('primaryForDark', 0.16),
        textColor: 'var(--color-primaryForDark)'
      },
      idle: {
        backgroundColor: transparentize('primaryForDark', 0.12),
        textColor: 'var(--color-primaryForDark)'
      }
    }
  } as const,
  light: {
    ghost: {
      active: {
        backgroundColor: transparentize('primaryForLight', 0.12),
        textColor: 'var(--color-primaryForLight50)'
      },
      disabled: {
        backgroundColor: transparentize('neutral05', 0),
        textColor: 'var(--color-neutral30)'
      },
      hover: {
        backgroundColor: transparentize('primaryForLight', 0.08),
        textColor: 'var(--color-primaryForLight50)'
      },
      idle: {
        backgroundColor: transparentize('primaryForLight', 0),
        textColor: 'var(--color-primaryForLight50)'
      }
    },
    neutral: {
      active: {
        backgroundColor: 'var(--color-neutral20)',
        textColor: 'var(--color-neutral90)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral10)',
        textColor: 'var(--color-neutral30)'
      },
      hover: {
        backgroundColor: 'var(--color-neutral10)',
        textColor: 'var(--color-neutral90)'
      },
      idle: {
        backgroundColor: 'var(--color-neutral05)',
        textColor: 'var(--color-neutral90)'
      }
    },
    primary: {
      active: {
        backgroundColor: 'var(--color-primaryForLight60)',
        textColor: 'var(--color-white)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral10)',
        textColor: 'var(--color-neutral30)'
      },
      hover: {
        backgroundColor: 'var(--color-primaryForLight50)',
        textColor: 'var(--color-white)'
      },
      idle: {
        backgroundColor: 'var(--color-primary)',
        textColor: 'var(--color-white)'
      }
    },
    secondary: {
      active: {
        backgroundColor: transparentize('primaryForLight', 0.24),
        textColor: 'var(--color-primaryForLight60)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral10)',
        textColor: 'var(--color-neutral30)'
      },
      hover: {
        backgroundColor: transparentize('primaryForLight', 0.16),
        textColor: 'var(--color-primaryForLight60)'
      },
      idle: {
        backgroundColor: transparentize('primaryForLight', 0.12),
        textColor: 'var(--color-primaryForLight60)'
      }
    }
  } as const
};

/**
 * Export `buttonThemes´ constant.
 */

export const buttonThemes = variants.map(
  variant => `
      &[data-variant='${variant}'] {
        --button-background-color: ${buttonThemesConfig.light[variant].idle.backgroundColor};
        --button-text-color: ${buttonThemesConfig.light[variant].idle.textColor};
        --button-active-background-color: ${buttonThemesConfig.light[variant].active.backgroundColor};
        --button-active-text-color: ${buttonThemesConfig.light[variant].active.textColor};
        --button-hover-background-color: ${buttonThemesConfig.light[variant].hover.backgroundColor};
        --button-hover-text-color: ${buttonThemesConfig.light[variant].hover.textColor};
        --button-disabled-background-color: ${buttonThemesConfig.light[variant].disabled.backgroundColor};
        --button-disabled-text-color: ${buttonThemesConfig.light[variant].disabled.textColor};
      }

      [data-theme='dark'] &[data-variant='${variant}'] {
        --button-background-color: ${buttonThemesConfig.dark[variant].idle.backgroundColor};
        --button-text-color: ${buttonThemesConfig.dark[variant].idle.textColor};
        --button-active-background-color: ${buttonThemesConfig.dark[variant].active.backgroundColor};
        --button-active-text-color: ${buttonThemesConfig.dark[variant].active.textColor};
        --button-hover-background-color: ${buttonThemesConfig.dark[variant].hover.backgroundColor};
        --button-hover-text-color: ${buttonThemesConfig.dark[variant].hover.textColor};
        --button-disabled-background-color: ${buttonThemesConfig.dark[variant].disabled.backgroundColor};
        --button-disabled-text-color: ${buttonThemesConfig.dark[variant].disabled.textColor};
      }
    `
);
