/**
 * Module dependencies.
 */

import { Settings } from './types';

/**
 * `translateObject` util.
 */

export const translateObject = <T>(obj: T, languageCode: string): T | T[] => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    if (obj[0]?.languages_code?.code) {
      const matchingItem = obj.find(item => item.languages_code.code === languageCode);

      return matchingItem ? translateObject(matchingItem, languageCode) : undefined;
    }

    return obj.map(item => translateObject(item, languageCode));
  }

  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    result[key] = translateObject(value, languageCode);
  }

  return result;
};

/**
 * Export `localizeAgnosticSettings` normalizer.
 */

export const localizeAgnosticSettings = (settings: Settings, regionCode: Region, languageCode: string) => {
  if (!Array.isArray(settings.regionSettings)) {
    // Settings are already localized
    return settings;
  }

  const translated = translateObject(settings, languageCode) as Settings;
  const regionSettings = Array.isArray(translated.regionSettings)
    ? translated.regionSettings.find(regionSettings => regionSettings.region === regionCode)
    : translated.regionSettings;

  return {
    ...translated,
    regionSettings
  };
};
