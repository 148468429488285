/**
 * Module dependencies.
 */

import { CSSProperties, ComponentPropsWithoutRef, ElementType, useMemo } from 'react';
import { isExternalUrl } from 'src/core/utils/url';
import { setButtonLinkProps } from 'src/core/utils/links';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import linkIcon from 'src/assets/svgs/24/external-link.svg';
import styled from 'styled-components';

/**
 * Export `BaseButtonProps` type.
 */

export type BaseButtonProps = ComponentPropsWithoutRef<'button'> & {
  as?: ElementType;
  hasLinkIcon?: boolean;
  href?: string;
  icon?: string;
  iconStyle?: CSSProperties;
  isLoading?: boolean;
  rel?: string;
  stretch?: boolean;
  target?: string;
};

/**
 * Export `BaseWrapper` styled component.
 */

export const BaseWrapper = styled.button.attrs(setButtonLinkProps)<BaseButtonProps>`
  -webkit-tap-highlight-color: transparent;
  background-color: var(--button-background-color);
  border: none;
  border-radius: 32px;
  color: var(--button-text-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: 500;
  gap: 8px;
  justify-content: center;
  line-height: var(--button-line-height);
  outline: none;
  padding: var(--button-padding-v) var(--button-padding-h);
  place-items: center;
  position: relative;
  transition: var(--transition-default);
  transition-property: background-color, color;
  white-space: nowrap;
  width: max-content;

  &:focus,
  &:focus-within,
  &:hover {
    background-color: var(--button-hover-background-color);
    color: var(--button-hover-text-color);
  }

  &:active {
    background-color: var(--button-active-background-color);
    color: var(--button-active-text-color);
  }

  :disabled,
  [disabled] {
    background-color: var(--button-disabled-background-color);
    color: var(--button-disabled-text-color);
    cursor: default;
    pointer-events: none;
  }
`;

/**
 * Export `LoadingWrapper` styled component.
 */

export const ContentWrapper = styled.div<{ spin?: boolean }>`
  align-items: center;
  border-radius: 32px;
  display: flex;
  opacity: ${props => (props.spin ? 0 : 1)};
  transition: opacity var(--transition-default);

  &[data-loading='true'] {
    opacity: ${props => (props.spin ? 1 : 0)};
  }
`;

/**
 * Export `useIcon` hook.
 */

export function useIcon(props: BaseButtonProps) {
  const icon = useMemo(() => {
    if (props.icon) {
      return props.icon;
    }

    if (!props.href || !props.hasLinkIcon) {
      return;
    }

    return isExternalUrl(props.href) ? linkIcon : arrowIcon;
  }, [props.hasLinkIcon, props.href, props.icon]);

  return icon;
}
