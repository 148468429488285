/**
 * Module dependencies.
 */

import { ChangeEvent, ComponentPropsWithoutRef, MouseEventHandler, forwardRef } from 'react';
import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Svg } from 'src/components/core/svg';
import { formControlSizes, formControlStyles, formControlVariants } from 'src/components/core/forms/styles';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `InputProps` interface.
 */

export type InputProps = Omit<ComponentPropsWithoutRef<'input'>, 'size'> &
  FormGroupProps & {
    iconLeft?: string | TrustedHTML;
    iconRight?: string | TrustedHTML;
    isTextArea?: boolean;
    maxLength?: number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClickLeft?: MouseEventHandler<HTMLSpanElement>;
    onClickRight?: MouseEventHandler<HTMLSpanElement>;
  };

/**
 * FormControl styled component.
 */

const FormControl = styled.input<Pick<InputProps, 'error'>>`
  ${formControlStyles}

  caret-color: var(--color-primaryForLight40);

  ${Object.entries(formControlVariants).map(
    ([key, value]) => `
      [data-variant='${key}'] & {
        ${value}
      }
    `
  )}

  ${Object.entries(formControlSizes).map(
    ([key, value]) => `
      [data-size='${key}'] & {
        ${value}
      }
    `
  )}

  &[data-textarea='true'] {
    border-radius: var(--border-radius);
    height: 112px;
    resize: none;
  }
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  color: var(--color-neutral30);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  [data-theme='dark'] & {
    color: var(--color-neutral60);
  }

  &[data-clickable='true'] {
    cursor: pointer;
    transition: color var(--transition-default);

    &:hover {
      color: var(--color-neutral20);
    }
  }

  &[data-clickable='false'] {
    pointer-events: none;
  }

  [data-theme='dark'] &[data-clickable='true']:hover {
    color: var(--color-neutral50);
  }
`;

/**
 * Export `Input` component.
 */

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    disabled,
    error,
    helpText,
    iconLeft,
    iconRight,
    id,
    isTextArea,
    label,
    maxLength,
    onClickLeft,
    onClickRight,
    size = 'default',
    value,
    variant = 'filled',
    ...rest
  } = props;

  return (
    <FormGroup
      className={className}
      data-size={size}
      data-variant={variant}
      disabled={disabled}
      error={error}
      helpText={helpText}
      id={id}
      isTextArea={isTextArea}
      label={label}
      maxLength={maxLength}
      style={{
        position: 'relative'
      }}
      value={value}
    >
      <div style={{ gridArea: 'input', position: 'relative' }}>
        {iconLeft && (
          <Icon
            data-clickable={!!onClickLeft}
            icon={iconLeft}
            onClick={onClickLeft}
            size={'20px'}
            style={{ left: 12 }}
          />
        )}

        <FormControl
          {...omit(rest, ['as'])}
          as={isTextArea ? 'textarea' : 'input'}
          data-textarea={isTextArea}
          disabled={disabled}
          error={error}
          id={id}
          maxLength={maxLength}
          ref={ref}
          style={{
            ...(iconLeft && { paddingLeft: 40 }),
            ...(iconRight && { paddingRight: 40 })
          }}
          value={value}
        />

        {iconRight && (
          <Icon
            data-clickable={!!onClickRight}
            icon={iconRight}
            onClick={onClickRight}
            size={'16px'}
            style={{ right: 12 }}
          />
        )}
      </div>
    </FormGroup>
  );
});

/**
 * `Input` display name.
 */

Input.displayName = 'Input';
