/**
 * Module dependencies.
 */

import { CSSProperties } from 'react';
import { Svg } from 'src/components/core/svg';
import { Transition, TransitionStatus } from 'react-transition-group';
import spinnerSvg from 'src/assets/svgs/100/spinner.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  active?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: number;
};

/**
 * `keyframes`.
 */

const keyframes = css`
  @keyframes starting-fill {
    to {
      stroke-dashoffset: 270;
    }
  }

  @keyframes vary-loader-width {
    0% {
      stroke-dashoffset: 270;
    }

    50% {
      stroke-dashoffset: 170;
    }

    100% {
      stroke-dashoffset: 275;
    }
  }

  @keyframes complete-fill {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fade {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({ icon: spinnerSvg })`
  ${keyframes}
  --animation-spin: spin 1.6s 0.2s linear infinite;
  --animation-starting-fill: starting-fill 0.5s forwards;
  --animation-vary-loader-width: vary-loader-width 3s 0.5s linear infinite alternate;

  svg {
    color: var(--spinner-color);
    overflow: visible;
    pointer-events: none;
  }

  circle:nth-child(2) {
    animation: var(--animation-starting-fill), var(--animation-vary-loader-width), var(--animation-spin);
    transform-origin: 50px 50px;
  }

  circle:nth-child(3) {
    animation: var(--animation-spin);
    transform-origin: 50px 50px;
    visibility: hidden;
  }

  [data-active='false'] & circle:nth-child(3) {
    animation:
      complete-fill 0.5s linear forwards,
      var(--animation-spin);
    visibility: visible;
  }

  [data-active='false'] & circle:nth-child(2) {
    animation:
      var(--animation-starting-fill),
      var(--animation-vary-loader-width),
      var(--animation-spin),
      fade 0.1 0.5s linear forwards;
  }

  [data-active='false'] & {
    animation: fade 0.2s 0.7s linear forwards;
    transition: all 0s 0.9s;
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &,
  &::before {
    inset: 0;
    position: absolute;
  }

  &::before {
    background-color: var(--spinner-background);
    content: '';
    transition: opacity var(--transition-default);
  }

  &[data-active='false']::before {
    opacity: 0;
  }
`;

/**
 * Export `Spinner` component.
 */

export const Spinner = ({
  active,
  backgroundColor = 'var(--color-background)',
  color = 'var(--color-primary)',
  size = 32
}: Props) => (
  <Transition in={active} mountOnEnter timeout={1000} unmountOnExit>
    {(state: TransitionStatus) => (
      <Wrapper
        data-active={['entering', 'entered'].includes(state)}
        style={{ '--spinner-background': backgroundColor, '--spinner-color': color } as CSSProperties}
      >
        <Icon size={`${size}px`} />
      </Wrapper>
    )}
  </Transition>
);
