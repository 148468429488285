/**
 * Export `categoriesConfig` constant.
 */

export const categoriesConfig = {
  cta: {
    actions: {
      click: 'click'
    },
    label: 'CTA'
  },
  form: {
    actions: {
      submit: 'submit'
    },
    label: 'Form'
  },
  learning: {
    actions: {
      courseCompleted: 'course_completed',
      lessonCompleted: 'lesson_completed'
    },
    label: 'Learning'
  }
} as const;

/**
 * `CategoriesConfig` type.
 */

type CategoriesConfig = typeof categoriesConfig;

/**
 * Export `Category` type.
 */

export type Category = keyof CategoriesConfig;

/**
 * Export `Action` type.
 */

export type Action<TCategory extends Category> = keyof CategoriesConfig[TCategory]['actions'];
