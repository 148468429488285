/**
 * Module dependencies.
 */

import { enableActivityTracking, trackPageView } from '@snowplow/browser-tracker';
import { sendGTMEvent, sendSnowplowEvent } from 'src/core/utils/analytics';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * Constants.
 */

const appId = process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID;
const collectorUrl = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL;

/**
 * `handleTrackPage` function.
 */

const handleTrackPage = () => {
  if (!appId || !collectorUrl) {
    return;
  }

  trackPageView();

  enableActivityTracking({
    heartbeatDelay: 10,
    minimumVisitLength: 30
  });
};

/**
 * `handleClickEvent` function.
 */

const handleClickEvent = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  const clickEventName = target.closest('[data-click-event-name]')?.getAttribute('data-click-event-name');

  if (clickEventName) {
    sendSnowplowEvent('cta', 'click', { label: clickEventName });
    sendGTMEvent('cta', 'click', clickEventName);
  }
};

/**
 * Export `useSnowplowTracking` hook.
 */

export function useSnowplowTracking(): void {
  const router = useRouter();

  useEffect(() => {
    handleTrackPage();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleTrackPage);

    return () => {
      router.events.off('routeChangeComplete', handleTrackPage);
    };
  }, [router.events]);

  useEffect(() => {
    document.addEventListener('click', handleClickEvent);

    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);
}
