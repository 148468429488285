/**
 * Module dependencies.
 */

import { BaseButtonProps, BaseWrapper, ContentWrapper, useIcon } from './common';
import { Spinner } from 'src/components/core/spinner';
import { Svg } from 'src/components/core/svg';
import { buttonThemes } from './button-theme-config';
import { forwardRef } from 'react';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = BaseButtonProps & {
  size?: 'large' | 'medium' | 'small';
  variant?: 'primary' | 'secondary' | 'ghost' | 'neutral';
};

/**
 * `buttonSizes` constant.
 */

const buttonSizes = css`
  --button-font-size: 16px;
  --button-icon-size: 20px;
  --button-line-height: 20px;
  --button-padding-h: 12px;
  --button-padding-v: 8px;

  ${media.min.md`
    &[data-size='large'] {
      --button-font-size: 20px;
      --button-icon-size: 24px;
      --button-line-height: 1.3;
      --button-padding-h: 16px;
      --button-padding-v: 12px;
    }
  `}

  &[data-size='small'] {
    --button-icon-size: 16px;
    --button-padding-v: 6px;
  }
`;

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled(BaseWrapper).withConfig({
  shouldForwardProp: prop => !['hasLinkIcon'].includes(prop)
})`
  ${buttonSizes}
  ${buttonThemes}
`;

/**
 * Export `Button` component.
 */

export const Button = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const { children, iconStyle, isLoading, size = 'medium', stretch, variant = 'primary', ...rest } = props;
  const icon = useIcon(props);
  const isDesktop = useBreakpoint('md');
  const loadingSize = isDesktop ? 24 : 20;

  return (
    <Wrapper
      data-size={size}
      data-variant={variant}
      ref={ref}
      {...rest}
      style={{
        ...(stretch && { width: '100%' }),
        ...(icon && { paddingLeft: 'calc(var(--button-padding-h) + 2px)' }),
        ...rest.style
      }}
    >
      {children && <ContentWrapper data-loading={isLoading}>{children}</ContentWrapper>}

      {isLoading && (
        <ContentWrapper data-loading={isLoading} spin>
          <Spinner
            active
            backgroundColor={'var(--button-background-color)'}
            color={'var(--button-text-color)'}
            size={loadingSize}
          />
        </ContentWrapper>
      )}

      {icon && (
        <ContentWrapper data-loading={isLoading}>
          <Svg icon={icon} size={'var(--button-icon-size)'} style={iconStyle} />
        </ContentWrapper>
      )}
    </Wrapper>
  );
});

/**
 * `Button` display name.
 */

Button.displayName = 'Button';
