/**
 * Module dependencies.
 */

import { ComponentType, ForwardedRef, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { Dropdown, DropdownProps } from 'src/components/core/dropdown';
import {
  DropdownIndicator,
  LoadingIndicator,
  NoResultsWrapper,
  SelectButton,
  SelectOption,
  SelectOptions,
  SelectWrapper
} from 'src/components/core/forms/select/styles';

import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Input } from 'src/components/core/forms/input';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { Virtuoso } from 'react-virtuoso';
import { genericForwardRef } from 'src/core/utils/generic-forward-ref';
import checkIcon from 'src/assets/svgs/16/check.svg';
import chevronIcon from 'src/assets/svgs/24/chevron-down.svg';
import closeIcon from 'src/assets/svgs/16/close.svg';
import searchIcon from 'src/assets/svgs/20/search.svg';
import spinnerIcon from 'src/assets/svgs/24/spinner.svg';

/**
 * Export `Option` type.
 */

export type Option<T extends Record<string, any> | undefined = undefined> = {
  href?: string;
  label: string;
  value: string | number;
} & (T extends undefined ? object : { props: T });

/**
 * Export `OptionProps` type.
 */

export type OptionProps<T extends Record<string, any> | undefined = undefined> = Option<T> & {
  disabled?: boolean;
  isSelected?: boolean;
};

/**
 * Export `SelectProps` type.
 */

export type SelectProps<T extends Record<string, any> | undefined = undefined> = Omit<
  FormGroupProps,
  'defaultValue' | 'value'
> & {
  components?: {
    Option?: ComponentType<{ option: OptionProps<T> }>;
    Value?: ComponentType<{ option?: Option<T> | null; placeholder?: string }>;
  };
  dropdownPosition?: DropdownProps['position'];
  filterPlaceholder?: string;
  icon?: string | TrustedHTML;
  isFilterable?: boolean;
  isLoading?: boolean;
  noResultsDescription?: string;
  noResultsLabel?: string;
  onChange?: (value: Option<T>) => void;
  options: Option<T>[];
  placeholder?: string;
  value?: Option<T> | null;
  virtualized?: boolean;
};

/**
 * `DefaultValueComponent` component.
 */

const DefaultValueComponent = ({ option, placeholder }: { option?: Option | null; placeholder?: string }) => {
  return <span>{option?.label ?? placeholder}</span>;
};

/**
 * `DefaultOptionComponent` component.
 */

const DefaultOptionComponent = ({ option }: { option: OptionProps }) => {
  return (
    <>
      {option.label}

      {option.isSelected && <Svg color={'var(--color-primary)'} icon={checkIcon} size={'16px'} />}
    </>
  );
};

/**
 * Export `Select` component.
 */

export const Select = genericForwardRef(
  <T extends Record<string, any> | undefined = undefined>(props: SelectProps<T>, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      components = {},
      disabled,
      dropdownPosition = 'full',
      error,
      filterPlaceholder,
      helpText,
      icon,
      id,
      isFilterable,
      isLoading,
      label,
      noResultsDescription,
      noResultsLabel,
      onChange,
      options,
      placeholder,
      size = 'default',
      style,
      value,
      variant = 'filled',
      virtualized
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);
    const selectedOptionRef = useRef<HTMLLIElement>(null);
    const ValueComponent = components.Value || DefaultValueComponent;
    const OptionComponent = components.Option || DefaultOptionComponent;

    const filtered: Option<T>[] = useMemo(
      () =>
        options.filter(
          ({ label, value }) =>
            !filter ||
            String(value).toLowerCase().includes(filter.toLowerCase()) ||
            label.toLowerCase().includes(filter.toLowerCase())
        ),
      [filter, options]
    );

    const handleToggle: MouseEventHandler<HTMLButtonElement> = useCallback(
      event => {
        event.stopPropagation();
        setIsOpen(prev => !prev);

        if (!isOpen) {
          setFilter('');
          setTimeout(() => {
            selectedOptionRef.current?.scrollIntoView({ block: 'nearest' });
          }, 0);
        }
      },
      [isOpen]
    );

    const handleChange = useCallback(
      (option: Option<T>) => {
        onChange?.(option);
        setIsOpen(false);
        setFilter('');
      },
      [onChange]
    );

    const renderOption = useCallback(
      (option: Option<T>) => (
        <SelectOption
          data-selected={option === value}
          key={option.value}
          onClick={() => handleChange(option)}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault();
              handleChange(option);
            }
          }}
          ref={option === value ? selectedOptionRef : undefined}
          tabIndex={0}
        >
          <OptionComponent option={{ ...option, isSelected: option === value }} />
        </SelectOption>
      ),
      [OptionComponent, handleChange, value]
    );

    return (
      <FormGroup
        className={className}
        data-select-size={size}
        data-select-variant={variant}
        disabled={disabled}
        error={error}
        helpText={helpText}
        id={id}
        label={label}
        value={value?.value}
      >
        <SelectWrapper ref={ref} style={style}>
          <SelectButton id={id} onClick={handleToggle} type={'button'}>
            {icon && <Svg icon={icon} size={'20px'} />}

            <ValueComponent option={value} placeholder={placeholder} />

            {isLoading ? (
              <LoadingIndicator icon={spinnerIcon} size={'24px'} />
            ) : (
              <DropdownIndicator data-open={isOpen} icon={chevronIcon} size={'24px'} />
            )}
          </SelectButton>

          <Dropdown
            focusable={false}
            isOpen={isOpen && !isLoading}
            onRequestClose={() => setIsOpen(false)}
            position={dropdownPosition}
            style={{ borderRadius: 8 }}
            top={8}
          >
            {isFilterable && (
              <div style={{ padding: '8px 8px 2px' }}>
                <Input
                  autoFocus
                  iconLeft={searchIcon}
                  id={`${id}-search`}
                  onChange={({ target }) => setFilter(target.value)}
                  onClick={event => event.stopPropagation()}
                  placeholder={filterPlaceholder}
                  ref={searchInputRef}
                  size={'medium'}
                  value={filter}
                  variant={'filled'}
                  {...(!!filter?.length && {
                    iconRight: closeIcon,
                    onClickRight: event => {
                      event.stopPropagation();
                      setFilter('');
                      searchInputRef.current?.focus();
                    }
                  })}
                />
              </div>
            )}

            <SelectOptions>
              {virtualized && filtered.length > 16 ? (
                <Virtuoso
                  initialTopMostItemIndex={
                    value?.value ? filtered.findIndex(option => option.value === value.value) : 0
                  }
                  itemContent={index => renderOption(filtered[index])}
                  style={{ height: '280px' }}
                  totalCount={filtered.length}
                />
              ) : (
                filtered.map(renderOption)
              )}
            </SelectOptions>

            {!filtered.length && (
              <NoResultsWrapper>
                <Text fontWeight={700} variant={'paragraph2'}>
                  {noResultsLabel}
                </Text>

                <Text color={'neutral40'} variant={'small'}>
                  {noResultsDescription}
                </Text>
              </NoResultsWrapper>
            )}
          </Dropdown>
        </SelectWrapper>
      </FormGroup>
    );
  },
  'Select'
);
