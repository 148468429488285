/**
 * Module dependencies.
 */

import { Snowplow } from './snowplow';
import { googleAnalytics } from './google-analytics';
import { googleRecaptcha } from './google-recaptcha';
import { impact } from './impact';
import { linkedinPixel } from './linkedin-pixel';
import { oneTrust } from './one-trust';
import { tikTokPixel } from './tiktok';
import { useEffect } from 'react';
/**
 * Export `ApplicationScripts`.
 */

export function ApplicationScripts() {
  useEffect(() => {
    oneTrust();
    googleRecaptcha();
    googleAnalytics();
    impact();
    tikTokPixel();
    linkedinPixel();
  }, []);

  return <Snowplow />;
}
