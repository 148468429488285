/**
 * Module dependencies.
 */

import { Text } from 'src/components/core/text';
import { linkBaseStyles } from 'src/components/core/links';
import { textStyles } from 'src/styles/typography';
import styled, { css } from 'styled-components';

/**
 * Export variants config.
 */

export const formControlVariants = {
  ghost: css`
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    height: calc(var(--input-height) - 2px);
  `
};

/**
 * Export sizes config.
 */

export const formControlSizes = {
  medium: css`
    --input-height: 40px;
    --input-padding-y: 10px;
  `,
  small: css`
    --input-height: 36px;
    --input-padding-y: 8px;
  `
};

/**
 * Export `Label` styled component.
 */

export const Label = styled.label`
  ${textStyles.label2}

  color: var(--input-label-color);
  font-weight: 700;

  a {
    ${linkBaseStyles}
    text-decoration: underline;
  }
`;

/**
 * Export `HelpText` styled component.
 */

export const HelpText = styled(Text).attrs({ variant: 'small' })`
  color: var(--input-helptext-color);
`;

/**
 * Export `formControlStyles` styled component.
 */

export const formControlStyles = css`
  ${textStyles.label2}

  --input-height: 56px;
  --input-padding-y: 16px;
  --input-padding-x: 16px;

  appearance: none;
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--input-shadow);
  color: var(--input-color);
  height: var(--input-height);
  margin: 0;
  outline: none;
  padding: var(--input-padding-y) var(--input-padding-x);
  transition: var(--transition-default);
  transition-property: background-color, border-color, color;
  width: 100%;

  ::placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
    transition: color var(--transition-default);
  }

  :focus,
  :focus-within {
    outline: none;
  }
`;
