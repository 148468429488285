/**
 * Module dependencies.
 */

import { useState } from 'react';

/**
 * `Key` type.
 */

type Key = string | null;

/**
 * `CookieReturn` type.
 */

type CookieReturn = [unknown, (value: unknown) => void];

/**
 * `getCookie` util
 */

const getCookie = (name?: Key) => document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2);

/**
 * `setCookie` util
 */

const setCookie = (name: Key, value: string) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 2 * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};

/**
 * Export `useCookie` hook.
 */

export const useCookie = (key?: Key, initialValue?: unknown): CookieReturn => {
  const [storedValue, setStoredValue] = useState(() => {
    if (!key) {
      return initialValue;
    }

    try {
      const value = getCookie(key);

      if (value !== undefined && value !== null) {
        return JSON.parse(value);
      }

      throw new Error('Not value');
    } catch {
      if (initialValue) {
        setCookie(key, JSON.stringify(initialValue));

        return initialValue;
      }
    }
  });

  if (!key) {
    return [
      initialValue,
      () => {
        throw new Error('useCookie key cannot be null');
      }
    ];
  }

  const setValue = (valueToStore: any) => {
    setCookie(key, JSON.stringify(valueToStore));

    setStoredValue(valueToStore);
  };

  return [storedValue, setValue];
};
