/**
 * Module dependencies.
 */

import { DropdownIndicator, SelectButton } from 'src/components/core/forms/select/styles';
import { Option, Select } from 'src/components/core/forms/select';
import { getLocale } from 'src/core/utils/locale';
import { media } from 'src/styles/media';
import { normalizeLocaleSelect } from 'src/core/utils/normalize';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useMemo, useState } from 'react';
import { useHreflangs } from 'src/hooks/use-hreflangs';
import { useRouter } from 'next/router';
import { useSettings } from 'src/context/settings';
import localesSvg from 'src/assets/svgs/20/locales.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: 40px;
  position: relative;

  ${media.min.md`
    margin-bottom: 0;
    margin-left: auto;
  `}
`;

/**
 * `StyledSelect` styled component.
 */

const StyledSelect = styled(Select)`
  ${DropdownIndicator} {
    color: var(--color-primary);
  }

  ${SelectButton} {
    border-radius: 32px;
    color: var(--color-primary);
    gap: 8px;
    width: max-content;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: ${transparentize('primaryForDark', 0.08)};
    }
  }

  & [data-position] > ul {
    max-height: 196px;
  }

  & [data-position='left'],
  & [data-position='right'] {
    padding-right: 32px;
    width: max-content;

    ul {
      width: calc(100% + 32px);
    }
  }
`;

/**
 * Export `LocaleSelect` component.
 */

export const LocaleSelect = () => {
  const { globalSettings, locale } = useSettings();
  const { regions } = globalSettings ?? {};
  const [selectedLocale, setSelectedLocale] = useState(getLocale(locale?.regionCode, locale?.languageCode));
  const router = useRouter();
  const hrefLangs = useHreflangs();
  const options = useMemo(() => {
    const locales = normalizeLocaleSelect(regions);

    return locales.map(({ label, value }) => ({
      href: hrefLangs.find(hl => hl.locale === value)?.route ?? `/${value}`,
      label,
      value
    }));
  }, [hrefLangs, regions]);

  const value = useMemo(() => options.find(({ value }) => value === selectedLocale), [options, selectedLocale]);

  const handleChange = useCallback(
    ({ href, value }: Option) => {
      // This should be handle by the client side since the middleware always redirects with the i18n cookie.
      if (typeof window !== 'undefined' && value === '') {
        document.cookie = 'i18n=; Path=/';
      }

      if (href) {
        router.push(href);
      }

      setSelectedLocale(value as string);
    },
    [router]
  );

  const isDesktop = useBreakpoint('md');
  const isTablet = useBreakpoint('sm');
  const position = useMemo(() => {
    if (isDesktop) {
      return 'right';
    }

    if (isTablet) {
      return 'left';
    }

    return 'full';
  }, [isDesktop, isTablet]);

  if (!options.length) {
    return null;
  }

  return (
    <Wrapper>
      <StyledSelect
        dropdownPosition={position}
        icon={localesSvg}
        id={'locale'}
        onChange={handleChange}
        options={options}
        size={'small'}
        value={value}
        variant={'ghost'}
      />
    </Wrapper>
  );
};
