/**
 * Module dependencies.
 */

import { Action, Category, categoriesConfig } from 'src/core/constants/analytics';
import { trackStructEvent } from '@snowplow/browser-tracker';

/**
 * Constants.
 */

const gtagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;
const appId = process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID;
const collectorUrl = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL;

/**
 * Export `sendGTMEvent` util.
 */

export function sendGTMEvent<TCategory extends Category>(
  category: TCategory,
  action: Action<TCategory>,
  value?: string | number
) {
  if (typeof window.gtag === 'function' && gtagId) {
    // @ts-expect-error - The parameters are correctly enforcing the type
    window.gtag('event', categoriesConfig[category]?.actions?.[action], { value });
  }
}

/**
 * Export `sendSnowplowEvent` util.
 */

export function sendSnowplowEvent<TCategory extends Category>(
  category: TCategory,
  action: Action<TCategory>,
  options?: { label?: string; property?: string }
) {
  if (!appId || !collectorUrl) {
    return;
  }

  trackStructEvent({
    // @ts-expect-error - The parameters are correctly enforcing the type
    action: categoriesConfig[category]?.actions?.[action],
    category: categoriesConfig[category]?.label,
    ...options
  });
}
