/**
 * Module dependencies.
 */

import { NextRequest, NextResponse } from 'next/server';
import i18nConfig from 'i18n.config';

/**
 * Constants.
 */

const appDomain = process.env.NEXT_PUBLIC_APP_DOMAIN as Domain;

/**
 * Export `getDomainConfig` util.
 */

export function getDomainConfig() {
  return i18nConfig[appDomain];
}

/**
 * Export `getUserRegionConfig` util.
 */

export function getUserRegionConfig(request: NextRequest) {
  const country = request.headers.get('cf-ipcountry')?.toLowerCase() as Region;
  const continent = request.headers.get('cf-ipcontinent')?.toLowerCase() as Region;
  const { defaultRegion, regions } = getDomainConfig();

  return regions[country] ?? regions[continent] ?? regions[defaultRegion];
}

/**
 * Export `getUserLanguage` util.
 */

export function getUserLanguage(request: NextRequest): string {
  const regionConfig = getUserRegionConfig(request);
  const acceptLanguage = request.headers.get('accept-language');
  const userLanguage = acceptLanguage?.split(/[,;]/)[0].split('-')[0] ?? '';

  return regionConfig.languages.includes(userLanguage) ? userLanguage : regionConfig.languages[0];
}

/**
 * Export `getRegionRedirectResponse` util.
 */

export function getRegionRedirectResponse(request: NextRequest) {
  const userRegionConfig = getUserRegionConfig(request);
  const userLanguage = getUserLanguage(request);
  const isDefaultLanguage = userLanguage === userRegionConfig.languages[0];
  const url = request.nextUrl;
  const possiblePathLocale = url.pathname.split('/')[1] as Locale;
  const hasLocale = getDomainConfig().supportedLocales.includes(possiblePathLocale);
  const pathLocale = hasLocale ? possiblePathLocale : '';
  const isRoot = url.pathname === '/' || (hasLocale && url.pathname.split('/').length === 2);
  const targetLocale = userLanguage + (userRegionConfig.isDefault ? '' : `-${userRegionConfig.code}`);
  const i18nCookie = request.cookies.get('i18n')?.value as Locale | '';
  const rootPath = userRegionConfig.isDefault && isDefaultLanguage ? '' : targetLocale;
  const cookieRootPath =
    i18nCookie === '' || (i18nCookie && getDomainConfig().supportedLocales.includes(i18nCookie))
      ? i18nCookie
      : rootPath;

  let response = NextResponse.next();

  const setI18nCookie = (response: NextResponse, value: string) => {
    response.cookies.set('i18n', value);

    return response;
  };

  if (userRegionConfig.isForced) {
    if (pathLocale !== targetLocale) {
      url.pathname = url.pathname.replace(`/${pathLocale}`, ``);
      url.pathname = `${targetLocale}${url.pathname}`;

      response = NextResponse.redirect(url);
    }

    return setI18nCookie(response, targetLocale);
  }

  if (isRoot && !hasLocale && url.pathname !== `/${cookieRootPath}`) {
    url.pathname = cookieRootPath;
    response = NextResponse.redirect(url);

    return setI18nCookie(response, cookieRootPath);
  }

  return setI18nCookie(response, pathLocale);
}
